import {Inject, Injectable} from '@angular/core';
import {Action, State, StateContext} from "@ngxs/store";
import {finalize, map} from "rxjs/operators";
import {ConfirmacaoUsuario, IniciarState, ReenviarEmailConfirmacao} from "./usuario-manutencao.actions";
import {UsuarioManutencaoDataService} from "@identidade/services/data-services/usuario-manutencao-data.service";
import {NotificationService} from "ui-shared";

export class ConfirmacaoUsuarioStateModel {
    processando: boolean;
    confirmado: boolean;
    tokenInvalido:boolean;
}

@Injectable()
@State<ConfirmacaoUsuarioStateModel>({
    name: 'ConfirmarUsuario',
    defaults: {
        processando: false,
        confirmado: false,
        tokenInvalido: false
    }
})

export class UsuariosManutencaoState {
    constructor(
        @Inject(UsuarioManutencaoDataService) private usuariosDataService: UsuarioManutencaoDataService,
        @Inject(NotificationService) private notification: NotificationService,
    ){}

    @Action(ConfirmacaoUsuario)
    public confirmarUsuario({ patchState }: StateContext<ConfirmacaoUsuarioStateModel>, { payload }: ConfirmacaoUsuario){
        let token = payload.token;
        let email = payload.email;
        let senha = payload.senha;
        let recuperarSenha: boolean = payload.recuperarSenha;
        let confirmacaoSenha = payload.confirmacaoSenha;

        patchState({processando: true});

        if(senha !== confirmacaoSenha){
            patchState({processando: false});
            this.notification.openError("A senha e a confirmação devem ser iguais!");
            return;
        }

        return this.usuariosDataService.confirmarUsuario(email, senha, token, recuperarSenha)
            .pipe(
                map((retorno) => {

                    if(retorno.data.tokenInvalido){
                        patchState({tokenInvalido: true})
                    }else{
                        patchState({confirmado: true})
                    }
                }),
                finalize(() => {
                    patchState({processando: false});
                })
            );
    }

    @Action(IniciarState)
    public InicializarState({ patchState }: StateContext<ConfirmacaoUsuarioStateModel>, { payload }: IniciarState){
        patchState(payload);
    }

    @Action(ReenviarEmailConfirmacao)
    public ReenviarEmailConfirmacao({ patchState }: StateContext<ConfirmacaoUsuarioStateModel>, { email }: ReenviarEmailConfirmacao){
        patchState({processando: true});

        return this.usuariosDataService.reenviarEmailConfirmacao(email)
            .pipe(map(() =>  patchState({confirmado: true})),
                finalize(() => {
                    patchState({processando: false});
                })
            );
    }
}
