import { DatePipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { Subscription } from "rxjs";
import { pairwise, tap } from "rxjs/operators";
import { NotificationService } from "../services";
import { Condicao } from "./models/condicao.model";
import { FiltroOpcao } from "./models/filtro-opcao.model";
import { FiltroSelecionado } from "./models/filtro-selecionado.model";
import { FiltroSubmit } from "./models/filtro-submit.model";
import { Filtro } from "./models/filtro.model";

const TEXT = 'Text';
const NUMBER = 'Number';
const DATE = 'Date';
const BOOLEAN = 'Boolean';
const ENUM = 'Enum';
const OPTIONS = 'Options';

const CONTEM = 'Contains';
const NAO_CONTEM = '!Contains';
const IGUAL = '==';
const DIFERENTE = '!=';
const MAIOR = '>';
const MENOR = '<';
const MAIOR_IGUAL = '>=';
const MENOR_IGUAL = '<=';

@Component({
    selector: 'ui-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    ],
})
export class FilterComponent implements OnInit, OnDestroy {
    @Output() public submitEvent = new EventEmitter();

    @Input() filtros: Filtro[];
    @Input() selecionados!: FiltroSelecionado[];
    @Input() aberto: boolean = false;

    filtrosForm = new FormArray([]);
    private subscriptions: Subscription[] = [];

    public condicoes: Condicao[] = [
        { descricao: 'Contém', valor: CONTEM, tiposPermitidos: [TEXT, ENUM] } as Condicao,
        { descricao: 'Não contém', valor: NAO_CONTEM, tiposPermitidos: [TEXT, ENUM] } as Condicao,
        { descricao: 'Igual a', valor: IGUAL, tiposPermitidos: [TEXT, NUMBER, BOOLEAN, ENUM, OPTIONS] } as Condicao,
        { descricao: 'Diferente de', valor: DIFERENTE, tiposPermitidos: [TEXT, NUMBER, ENUM, OPTIONS] } as Condicao,
        { descricao: 'Maior que', valor: MAIOR, tiposPermitidos: [NUMBER, DATE] } as Condicao,
        { descricao: 'Menor que', valor: MENOR, tiposPermitidos: [NUMBER, DATE] } as Condicao,
        { descricao: 'Maior ou igual a', valor: MAIOR_IGUAL, tiposPermitidos: [NUMBER, DATE] } as Condicao,
        { descricao: 'Menor ou igual a', valor: MENOR_IGUAL, tiposPermitidos: [NUMBER, DATE] } as Condicao,
    ];

    constructor(
        private datePipe: DatePipe,
        private notification: NotificationService,
    ) { }

    ngOnInit(): void {
        const novoForm = this.montaFormArray(this.selecionados);
        this.filtrosForm = new FormArray(novoForm);

        if (this.selecionados) {
            this.filtrosForm.markAsTouched();
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    public condicoesFiltradas(propriedade: string): Condicao[] {
        let filtro = this.filtros.find(x => x.propriedade == propriedade);
        return this.condicoes.filter(condicao => condicao.tiposPermitidos.includes(filtro?.tipo))
    }

    public removerFiltro(index: number): void {
        if (this.filtrosForm.length === 1) {
            let form = this.filtrosForm.controls[index] as FormGroup;
            form.reset();
        } else {
            this.filtrosForm.removeAt(index);
        }
    }

    public limpaForm(): void {
        this.filtrosForm = new FormArray(this.montaFormArray());
        this.submit();
    }

    public adicionarFiltro(): void {
        const ultimoFiltro = this.filtrosForm.at(this.filtrosForm.length - 1) as FormGroup;

        const camposPreenchidos = Object.keys(ultimoFiltro.controls).every(key => {
            const control = ultimoFiltro.get(key);
            return control && control.value !== null && control.value !== '';
        });

        if (!camposPreenchidos && this.filtrosForm.length > 1) {
            this.notification.openError('Por favor, preencha todos os campos do filtro atual antes de adicionar um novo.');
            return;
        }

        this.filtrosForm.push(this.montaFormGroup());
    }


    public exibirAdicionar(index: number): boolean {
        return this.filtrosForm.length === index + 1;
    }

    public exibirCampoData(propriedade: string): boolean {
        let filtro = this.filtros.find(x => x.propriedade === propriedade);
        return filtro?.tipo === DATE;
    }

    public exibirCampoBooleano(propriedade: string): boolean {
        let filtro = this.filtros.find(x => x.propriedade === propriedade);
        return filtro?.tipo === BOOLEAN;
    }

    public exibirCampoPadrao(propriedade: string): boolean {
        let filtro = this.filtros.find(x => x.propriedade === propriedade);
        return filtro?.tipo !== BOOLEAN && filtro?.tipo !== DATE && filtro?.tipo !== OPTIONS;
    }

    public options(propriedade: string): Array<FiltroOpcao> {
        return this.filtros.find(f => f.propriedade === propriedade)?.opcoes || []
    }

    public exibirOptions(propriedade: string): boolean {
        let filtro = this.filtros.find(x => x.propriedade === propriedade);
        return filtro?.tipo === OPTIONS;
    }


    public abrirFiltros(): void {
        this.aberto = !this.aberto;
    }

    public submit() {
        if (this.filtrosForm.controls.length > 1) {
            this.filtrosForm.controls = this.filtrosForm.controls
                .filter((filtro: AbstractControl, index: number) => {
                    const formGroup = filtro as FormGroup;
                    const camposPreenchidos = Object.keys(formGroup.controls).every(key => {
                        const control = formGroup.get(key);
                        return control && control.value !== null && control.value !== '';
                    });

                    if (index === 0) {
                        return true;
                    }

                    return camposPreenchidos;
                });
        }

        const primeiroFiltro = this.filtrosForm.at(0) as FormGroup;
        const primeiroFiltroValido = Object.keys(primeiroFiltro.controls).every(key => {
            const control = primeiroFiltro.get(key);
            return control && (control.value !== null && control.value !== '');
        });

        if (!primeiroFiltroValido && this.filtrosForm.controls.length > 1) {
            this.notification.openError('Nenhum filtro válido foi encontrado. Por favor, preencha ao menos um filtro para fazer a pesquisa.');
            return;
        }

        let filtrosValidados = this.filtrosForm.controls
            .map(filtro => (filtro as FormGroup).getRawValue() as FiltroSelecionado)
            .filter(filtro => filtro.propriedade && filtro.condicao && filtro.termo);

        if (filtrosValidados.length === 0) {
            filtrosValidados = [];
        }

        this.submitEvent.emit({ query: this.montaFiltro(filtrosValidados), filtros: filtrosValidados } as FiltroSubmit);
    }

    private montaFormArray(filtros: FiltroSelecionado[] = null): FormGroup[] {
        return filtros?.map(filtro => this.montaFormGroup(filtro)) || [this.montaFormGroup()];
    }

    private montaFormGroup(selecionado: FiltroSelecionado = null): FormGroup {
        const form = new FormGroup({
            propriedade: new FormControl(selecionado?.propriedade, [Validators.required]),
            condicao: new FormControl(selecionado?.condicao),
            termo: new FormControl(selecionado?.termo),
        });

        if (selecionado) {
            form.patchValue(selecionado);

            for (let i in form.controls) {
                form.controls[i].markAsTouched();
            }
        }


        this.subscriptions.push(form.valueChanges.pipe(
            pairwise(),
            tap(([anterior, novo]: [any, any]) => {
                const tipoAnterior = this.filtros.find(x => x.propriedade == anterior.propriedade)?.tipo;
                const tipoNovo = this.filtros.find(x => x.propriedade == novo.propriedade)?.tipo;

                if (tipoAnterior == DATE && tipoAnterior != tipoNovo) {
                    let formAtualIndex = this.filtrosForm.getRawValue().findIndex(x =>
                        x.propriedade == novo.propriedade &&
                        x.termo == novo.termo &&
                        x.condicao == novo.condicao
                    );

                    if (formAtualIndex > -1) {
                        let formAtual = this.filtrosForm.controls[formAtualIndex];
                        formAtual.patchValue({ termo: null, condicao: null });
                    }

                }
            })
        ).subscribe());

        return form;
    }

    private montaFiltro(filtros: FiltroSelecionado[]) {
        let query = '';
        for (let index = 0; index < filtros.length; index++) {
            const filtroSelecionado = filtros[index];
            const filtro = this.filtros.find(x => x.propriedade == filtroSelecionado.propriedade);
            const tipoFiltro = filtro?.tipo;
            let termo = filtroSelecionado.termo;
            let filtroQuery = ''

            if (tipoFiltro == ENUM) {
                termo = filtroSelecionado.termo.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(' ', '');
            }

            if (tipoFiltro == DATE) {

                let horaLimite = '00:00:00'
                if (filtroSelecionado.condicao === MAIOR || filtroSelecionado.condicao === MENOR_IGUAL) {
                    horaLimite = '23:59:59'
                }

                termo = this.datePipe.transform(new Date(filtroSelecionado.termo), `yyyy-MM-dd ${horaLimite}`);

            }

            if (filtroSelecionado.condicao == CONTEM) {
                filtroQuery = `${filtroSelecionado.propriedade}.ToUpper().Contains("${termo.toUpperCase()}")`;
            }
            else if (filtroSelecionado.condicao == NAO_CONTEM) {
                filtroQuery = `!${filtroSelecionado.propriedade}.ToUpper().Contains("${termo.toUpperCase()}")`;
            }
            else {
                if (tipoFiltro == TEXT || tipoFiltro == ENUM) {
                    filtroQuery = `${filtroSelecionado.propriedade}.ToUpper() ${filtroSelecionado.condicao} "${termo.toUpperCase()}"`;
                } else if (tipoFiltro == OPTIONS || tipoFiltro == DATE) {
                    if (filtro.pesquisaEmLista) {
                        filtroQuery = `${filtroSelecionado.propriedade}.Any(x => x ${filtroSelecionado.condicao} "${termo}")`;
                    }
                    else {
                        filtroQuery = `${filtroSelecionado.propriedade} ${filtroSelecionado.condicao} "${termo}"`;
                    }
                }
                else {
                    filtroQuery = `${filtroSelecionado.propriedade} ${filtroSelecionado.condicao} ${termo}`;
                }
            }

            if (filtroSelecionado.propriedade === 'Id') {
                filtroQuery = `${filtroSelecionado.propriedade} == Guid(${termo})`;
            }

            if (index > 0) {
                query += ' AND ';
            }

            query += filtroQuery;
        }

        return query;
    }
}
