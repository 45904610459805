import {API_URL, ApiResult} from 'ui-shared';
import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {ConfirmarUsuarioResult} from "@identidade/models/confirmar-usuario.result";

@Injectable({ providedIn: 'root' })
export class UsuarioManutencaoDataService {

    protected url: string;

    constructor(
        @Inject(API_URL) urlBase: string,
        @Inject(HttpClient) protected httpClient: HttpClient
    ) {
        this.url = `${urlBase}/v1/usuario`;
    }

    confirmarUsuario(email: string, senha: string, token: string, recuperarSenha: boolean = false) {

        return this.httpClient.get<ApiResult<ConfirmarUsuarioResult>>(
            this.url +"/ConfirmarUsuario", {
                params: {
                    token: token,
                    email: email,
                    senha: senha,
                    recuperarSenha: recuperarSenha.toString()
                } });
    }

    reenviarEmailConfirmacao(usuarioEmail: string ) {
        return this.httpClient.post(this.url + `/ReenviarNaoConfirmado/${usuarioEmail}`,null,
            {
                params:{
                    usuarioEmail: "true"
                }
            });
    }
}
