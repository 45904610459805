import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {UsuarioManutencaoService} from "./usuario-manutencao.service";
import {UsuarioManutencaoSelector} from "./usuario-manutencao-store/usuario-manutencao.selectors";
import {Select, Store} from "@ngxs/store";
import {Observable} from "rxjs";
import {IniciarState} from "./usuario-manutencao-store/usuario-manutencao.actions";

@Component({
    selector: 'app-usuario-manutencao',
    templateUrl: './usuario-manutencao.component.html',
    styleUrls: ['./usuario-manutencao.component.scss']
})
export class UsuarioManutencaoComponent implements OnInit {

    @Select(UsuarioManutencaoSelector.processando)
    public processando$ : Observable<boolean>;

    @Select(UsuarioManutencaoSelector.confirmado)
    public confirmado$ : Observable<boolean>;

    @Select(UsuarioManutencaoSelector.tokenInvalido)
    public tokenInvalido$ : Observable<boolean>;

    public usuarioform: FormGroup;
    public recuperarSenha: boolean = false;
    public esconderSenha: boolean = true;
    public esconderConfirmacaoSenha: boolean = true;

    constructor(
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        public service: UsuarioManutencaoService,
        private store: Store
    ) { }

    ngOnInit(): void {
        const { recuperarsenha } = this.route.snapshot.queryParams;
        this.usuarioform = this.formBuilder.group({
            senha: [null, [Validators.required, Validators.minLength(2)]],
            confirmacaoSenha: [null, [Validators.required, Validators.minLength(2)]]
        });

        this.recuperarSenha = recuperarsenha == "true";

        this.store.dispatch(new IniciarState({ tokenInvalido:false }));
    }
}
