
export class ConfirmacaoUsuario {
  public static readonly type = '[UsuarioManutencao] Confirmacao';
  constructor(public payload: any) { }
}

export class IniciarState {
    public static readonly type = '[UsuarioManutencao] IniciarState';
    constructor(public payload: any) { }
}

export class ReenviarEmailConfirmacao {
    public static readonly type = '[UsuarioManutencao] Reenviar Email Confirmacao';
    constructor(public email: string) { }
}
