export const TIPO_RFB_LISTA_ESTATICA = 'LISTA_ESTATICA';

export const TIPO_ARQUIVO_EXCEL = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const TIPO_ARQUIVO_JSON = 'application/json';

export const STATUS_ENVIO_INTEGRADO = 'Integrado';
export const STATUS_ENVIO_VALIDADO = 'Validado';
export const STATUS_ENVIO_RETIFICADO = 'Retificado';
export const STATUS_ENVIO_LIDO_POR_TERCEIRO = 'Lido por sistema terceiro';
export const STATUS_ENVIO_PENDENTE = 'Pendente';
export const STATUS_ENVIO_AGUARDANDO = 'Aguardando envio';
export const STATUS_ENVIO_PROBLEMA_ENVIO = 'Problema no envio';
export const STATUS_ENVIO_ENVIANDO = 'Enviando';
export const IDENTIDADE_API_URL = 'IDENTIDADE_API_URL';
export const CONFIGURACAO_API_URL = 'CONFIGURACAO_API_URL';
export const OPERACAO_API_URL = 'OPERACAO_API_URL';
export const AUTENTICACAO_PORTAL_API_URL = 'AUTENTICACAO_PORTAL_API_URL';
export const PROCESSADOR_SOLICITACAO_URL = 'PROCESSADOR_SOLICITACAO_URL';
