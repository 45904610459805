import { Component, Inject, NgZone, OnInit, } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService, MasterService } from 'ui-shared';

@Component({
    selector: 'app-root',
    template: `<router-outlet></router-outlet>`
})
export class AppComponent implements OnInit {
    
    constructor(
        @Inject(Router) private router: Router,
        @Inject(LoginService) private loginService: LoginService,
        @Inject(MasterService) private masterService: MasterService,
        @Inject(NgZone) private ngZone: NgZone
    ) {
    }

    ngOnInit(): void {
    
    }
}

