import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MasterComponent} from 'ui-shared';
import {AcessoNegadoComponent} from './features/acesso-negado/acesso-negado.component';
import {IdentidadeGuard} from './services/IdentidadeGuard';
import {UsuarioManutencaoComponent} from "./features/usuario-manutencao/usuario-manutencao.component";

const routes: Routes = [
    {
        path: 'login',
        loadChildren: () => import('./features/login/login.module')
            .then(m => m.LoginModule)
    },
    { path: 'acesso-negado', component: AcessoNegadoComponent },
    { path: 'confirmar-usuario', component: UsuarioManutencaoComponent },
    {
        path: '', component: MasterComponent, canActivate: [IdentidadeGuard],
        children: [
            {
                path: 'conta',
                loadChildren: () => import('./features/conta/conta.module')
                    .then(m => m.ContaModule)
            },
            {
                path: 'contrato',
                loadChildren: () => import('./features/contrato/contrato.module')
                    .then(m => m.ContratoModule)
            },
            {
                path: 'modulo',
                loadChildren: () => import('./features/modulo/modulo.module')
                    .then(m => m.ModuloModule)
            },
            {
                path: 'usuario',
                loadChildren: () => import('./features/usuario/usuarios.module')
                    .then(m => m.UsuariosModule)
            },
            {
                path: 'home',
                loadChildren: () => import('./features/home/home.module')
                    .then(m => m.HomeModule)
            },
            { path: '**', redirectTo: 'home' },
        ]
    },

    { path: '**', redirectTo: 'home' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
