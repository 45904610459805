import { EnumModulos } from "./login-state-model";
import { LoginEntity, LoginMfaEntity, LoginMicrosoftEntity } from "@identidade/models";

export class Login {
  public static readonly type = '[Login] Login data';
  constructor(public payload: LoginEntity) { }
}

export class LoginMfa {
  public static readonly type = '[Login] Login MFA data';
  constructor(public payload: LoginMfaEntity) { }
}

export class LoginMicrosoft {
  public static readonly type = '[Login] Login Microsoft';
  constructor(public payload: LoginMicrosoftEntity) { }
}

export class LogOut {
  public static readonly type = '[Login] LogOut data';
  constructor() { }
}
export class AtualizarCallback {
  public static readonly type = '[Login] Callback update';
  constructor(public payload: { redirectUrl: string; aplicacao: EnumModulos, contaId:string  }) { }
}

export class RecuperarSenha {
  public static readonly type = '[Login] Recuperar Senha';
  constructor(public email: string) { }
}

export class ReenviarCodigoMfa {
  public static readonly type = '[Login] Reenviar Código MFA';
  constructor() { }
}

export class RetornarParaLogin {
  public static readonly type = '[Login] Retornar para Login';
  constructor() { }
}
